@font-face {
  font-family: "Order-Regular";
  src: url("Order-Regular.ttf") format('truetype');
  /* Avoid Flash of Unstyled Text (FOUT) */
  font-display: block;
}
@font-face {
  font-family:"Chaos1-Regular";
  src: url("Chaos1-Regular.ttf") format('truetype');
  /* Avoid Flash of Unstyled Text (FOUT) */
  font-display: block;
}
@font-face {
  font-family:"Chaos2-Regular";
  src: url("Chaos2-Regular.ttf") format('truetype');
  /* Avoid Flash of Unstyled Text (FOUT) */
  font-display: block;
}

@import url('https://fonts.googleapis.com/css2?family=Agdasima&display=block');

:root{
--black:#121212;
--white:#ffffff;
--user-font:'Agdasima', sans-serif;
--green:#30db5b;
--blue:#0a84ff;
--red:#ff6961;
--yellow:#ffd426;
--user-font-size:1.5rem;
--ai-font:'Order-Regular';
/* --ai-font-two:'Darker Grotesque', sans-serif; */
/* --ai-font-three:'Agdasima', sans-serif; */
--ai-font-four:"Chaos1-Regular";
--ai-font-five:"Chaos2-Regular";
--ai-font-size:1.4rem;
--ai-normal-font-size:1rem;
--link-color:burlywood;
--suggestion-text-color:#808080;
--purple:#AF52DE;
--orange:#FFAA44;
--cyan:#0D817C;
--pink:#DA8FFF;
--alpha-pink:#FF2D55;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

body {
  background-color: var(--black);
  color: var(--white);
  font-family: var(--user-font);
  font-size: var(--user-font-size);
  letter-spacing: 1px;
  line-height: 2rem;
}

.App{
  padding:1rem;
}

.cli-container{
  width: 80%;
}

.cli-input {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  /* caret-color: transparent; */
  position: relative;
  width:100%;
}

.blinker {
  width: 8px;
  height: 2px;
  background-color: var(--white);
  animation: blinker-animation 1s infinite;
}

@keyframes blinker-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.cli-input-field {
  outline: none;
  border: none;
  font-size: inherit;
  font-family: var(--user-font);
  letter-spacing: 1px;
  color: var(--white);
  background-color: transparent;
  padding: 0;
  margin-left: 5px;
  cursor: pointer;
  width:100%;
}

.cli-output{
  display: flex;
  flex-direction: column;
  gap:15px;
}

.typewriter{
  font-family: var(--ai-font);
  
  font-size: var(--ai-font-size);
  color:var(--green);
}



.Typewriter__cursor{
  display: none;
}

.blinker-none{
  display: none;
}

.image-gallery {
  text-align: right;
  position: fixed;
  right:10px;
  top:12%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height:90vh;
  min-width:100px;
}

.gallery-button {
  background-color: transparent;
  color: var(--white);
  border: none;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  right:0px;
  bottom:20px;
  width:150px;
  z-index:5;
}

.gallery-button img{width:100%;}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  height:60vh;
  width:200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.responsive-image {
  width: 100%;
}


.gallery-container::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.gallery-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px; /* Color of the scrollbar track */
}

.gallery-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners of the thumb */
}

.gallery-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}

a{
  width:100%;
  /* color:var(--green); */
  color:currentColor;
}

.link-color{
  color: var(--link-color);
  font-size: var(--user-font-size);
}

#suggestion-text{
  position: absolute;
  top: 49%;
  left: 5px;
  transform: translateY(-50%);
  color: var(--suggestion-text-color);
  font-size: var(--user-font-size);
  font-family: var(--user-font);
  
  letter-spacing: 1px;
}

.logo{
  position: fixed;
  top: 10px;
  right: 10px;
  width:12%;
  z-index:999;
}

.bottom-icons{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap:1rem;
}

.bottom-icons .mona-lisa{
  width:1.8rem;
}

.mail{
  font-size: 2.2rem;
  position: relative;
  right:-3px;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: none;
}

.color-option{
  cursor: pointer;
  font-family: var(--user-font);
  
  font-size: var(--user-font-size);
  letter-spacing: 1px;
}

.color-option-red{
  color:var(--red);
}

.color-option-yellow{
  color:var(--yellow);
}

.color-option-purple{
  color:var(--purple);
}

.bottom-icons {
  opacity: 0;
}

.bottom-icons:hover{
  opacity: 1;
}

.bottom-icons.show {
  opacity: 1;
}

.color-options-container{
  display: flex;
  gap:20px;
  margin-top: 0.5rem;
}

.loader-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #000;
}

.loader-screen canvas {
  display: block;
}

.fontAI{
  font-family: var(--ai-font-four);
  
}

/* .fontAI{
  animation: fontAIChange 0.5s ease-in-out forwards;
}

@keyframes fontAIChange {
  0% {
    font-family: var(--ai-font);
  }
  100% {
    font-family: var(--ai-font-four);
  }
} */


.typewriter-container {
  font-family: var(--ai-font);
  font-size: var(--ai-font-size);
  color:var(--green);
}

.text-color-red {
  color:var(--red);
}

.info-container{
  align-items: stretch;
}

.easter-egg{
  color:var(--black);
  /* color:white; */
  font-size: var(--user-font-size);
}

.v1{
  font-family: var(--ai-font);
  font-size: var(--ai-font-size);
}

.v2{
  font-family: var(--ai-font-five);
  font-size: var(--ai-font-size);
}

.v3{
  font-family: var(--ai-font-four);
  font-size: var(--ai-font-size);
}

.change {
  animation: change 0.1s step-end;
} 

@keyframes change {
  0% {
    font-family: var(--ai-font-five);
    font-size: var(--ai-font-size);
    
  }
  100% {
    font-family: var(--ai-font);
    
  }
}

.classPerson{
  color:var(--pink);
  /* text-decoration: underline; */
}

.classNumber{
  /* color:var(--orange); */
  /* text-decoration: underline; */
  color:var(--pink);
}

.classEmail{
  /* color:var(--cyan); */
  /* text-decoration: underline; */
  color:var(--pink);
}

.classAlphaNumSym{
  /* color:var(--alpha-pink); */
  /* text-decoration: underline; */
  color:var(--pink);
}

.underlineClass{
  /* text-decoration: underline; */
  color:var(--pink);
}